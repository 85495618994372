import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import type { ContentfulBusinessesBlock } from '../../generated/graphql-types'
import { Company } from '../../types/company'
import Spinner from '../atoms/spinner'
import theme from '../atoms/theme'
import { LAMBDA_URL } from '../constants'
import Button from '../molecules/button'
import {
  ContentBlockDescription,
  ContentBlockTitle,
  ContentBlockWrapper,
  HeadingWrapper,
} from '../molecules/content-blocks'
import DirectoryListings from '../organisms/directory/directory-listings'

type BusinessesBlockProps = {
  block: ContentfulBusinessesBlock
}

const SpinnerContainer = styled.div`
  padding: 8rem;
  text-align: center;
`

const spinnerStyle = {
  height: '20rem',
}

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const BusinessesBlock = (props: BusinessesBlockProps): JSX.Element => {
  const { block } = props
  const { title, description, buttonLabel, buttonUrl } = block
  const [isLoading, setLoading] = useState(true)
  const [companies, setCompanies] = useState<Company[]>([])

  const query = `
    query accountsQuery($limit: Int, $offset: Int) {
      accounts(limit: $limit, offset: $offset) {
        nodes {
          aboutUs
          businessHours
          category
          city
          companyLogo
          companyLogoURL
          companyName
          consented
          contactPerson
          emailAddress
          id
          image
          phoneNumber
          postcode
          serviceProductsList
          servicesProducts
          shortDescription
          sponsored
          street
          websiteSocialMediaLink
          
          billingAddress {
            city
            country
            geocodeAccuracy
            latitude
            longitude
            postalCode
            state
            street
          }
          businessPhone
          companyDescription
          primaryContactEmail
          primaryContactName
          secondLevelIndustry
        }
      }
    }
  `

  useEffect(() => {
    fetch(LAMBDA_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query,
        variables: {
          limit: null,
          offset: 0,
        },
      }),
    })
      .then((response) => response.json())
      .then((resultData) => {
        const sponsoredCompanies = resultData.data.accounts.nodes
          .filter((company: Company) => company.sponsored)
          .slice(0, 6)
        setCompanies(sponsoredCompanies)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching companies:', error)
        setLoading(false)
      })
  }, [])

  return (
    <ContentBlockWrapper>
      <HeadingWrapper>
        <ContentBlockTitle>{title}</ContentBlockTitle>
        {description?.description && (
          <ContentBlockDescription>
            {description.description}
          </ContentBlockDescription>
        )}
      </HeadingWrapper>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner style={spinnerStyle} alternative={true} />
        </SpinnerContainer>
      ) : (
        <>
          <DirectoryListings companies={companies} />
          <ButtonsWrapper>
            {buttonUrl && (
              <Button href={buttonUrl}>{buttonLabel || 'View All'}</Button>
            )}
          </ButtonsWrapper>
        </>
      )}
    </ContentBlockWrapper>
  )
}

export default BusinessesBlock
